import React from "react";
import { NumberFormat } from "../../../../lib/App/common/ValueFormatter";
import Translate from "../../../../lib/App/common/translator/components/translate";

const getSequence = (dataArray) => {
  let sequence = [];
  let maxValue = dataArray[0];
  let minValue = dataArray[0];
  for (var i = 0; i < dataArray.length; i++) {
    if (i == dataArray.length - 1 || dataArray[i + 1] != dataArray[i] + 1) {
      if (maxValue == minValue) {
        dataArray[i] === 0 ? sequence.push("EG") : sequence.push(dataArray[i]);
      } else {
        minValue === 0
          ? sequence.push("EG - " + maxValue)
          : sequence.push(minValue + ". - " + maxValue);
      }
      minValue = dataArray[i + 1];
      maxValue = dataArray[i + 1];
    } else if (dataArray[i + 1] == dataArray[i] + 1) {
      maxValue++;
    }
  }

  return sequence;
};

const BasicHightlights = (props) => {
  const flaeche = props.wohnungsgrundriss.flaeche || "";

  const etagen = props.wohnungsgrundriss.etageNummer || [];
  let etage;
  if (etagen.length >= 1) {
    etage = getSequence(etagen);
  } else {
    etage = parseInt(etagen[0]);
  }

  const translateEtageType =
    etagen.length >= 1 &&
    etage !== 0 &&
    (etagen[0] !== 0 || etagen.length > 1) ? (
      <Translate id="BasicHighlights/etage-type/og" data={{ floor: etage }} />
    ) : (
      <Translate id="BasicHighlights/etage-type/eg">EG</Translate>
    );

  const employees = props.konfigurationV.highlights
    ? props.konfigurationV.highlights["search-employee"].value +
      " " +
      props.konfigurationV.highlights["search-employee"].unit
    : "";

  const translateEtage = (
    <Translate id="BasicHighlights/etage">Etage</Translate>
  );
  const translateFlaeche = (
    <Translate id="BasicHighlights/flaeche">Fläche</Translate>
  );
  const translateEmployees = (
    <Translate id="BasicHighlights/mitarbeiter">Anzahl MA</Translate>
  );
  const translateRooms = (
    <Translate id="BasicHighlights/rooms">Meeting/Informalroom</Translate>
  );
  const translateRoomType = props.konfigurationV.highlights ? (
    <Translate
      id={
        "BasicHighlights/room-type/" +
        props.konfigurationV.highlights.sitzungszimmer.name
      }
    >
      {props.konfigurationV.highlights.sitzungszimmer.name}
    </Translate>
  ) : (
    ""
  );

  return (
    <table>
      <tbody>
        <tr>
          <td>{translateEtage}</td>
          <td>{translateEtageType}</td>
        </tr>
        <tr>
          <td>{translateFlaeche}</td>
          <td>
            <NumberFormat value={Number(flaeche)} /> m<sup>2</sup>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

const DeskShareRatio = (props) => {
  const buerolayout = props.highlights
    ? props.highlights.buerolayout.variationCode
    : "";
  const deskShareRatio =
    props.highlights && buerolayout !== "blnds"
      ? props.highlights["desk-share-ratio"].value +
        " " +
        props.highlights["desk-share-ratio"].unit
      : "";

  const deskShareTitle =
    buerolayout !== "blnds"
      ? "Desk Share Ratio"
      : props.highlights.buerolayout.name;
  const translateDeskShareTitle = (
    <Translate id="BasicHighlights/desk-share-ratio">
      {deskShareTitle}
    </Translate>
  );

  return (
    <tr>
      <td>{translateDeskShareTitle}</td>
      <td>{deskShareRatio}</td>
    </tr>
  );
};

export default BasicHightlights;
