import React, { Fragment } from "react";
import { CurrencyFormat } from "../../../../lib/App/common/ValueFormatter";
import Translate from "../../../../lib/App/common/translator/components/translate";

const ZusatzoptionenHighlights = (props) => {
  const konfigurationV = props.konfigurationV || {};
  const translateParkplatz = (
    <Translate id="ZusatzoptionenHighlights/parkplatz">
      Parkplatz pro Jahr
    </Translate>
  );
  const translatePricem2 = (
    <Translate id="ZusatzoptionenHighlights/pricem2">
      Totalpreis / m<sup>2</sup> und Jahr
    </Translate>
  );
  const translateBasePricem2 = (
    <Translate id="ZusatzoptionenHighlights/basepricem2">
      Basispreis / m<sup>2</sup> und Jahr
    </Translate>
  );

  return (
    <Fragment>
      <tr>
        <td>{translateBasePricem2}</td>
        <td>
          <CurrencyFormat
            value={konfigurationV.basisQmJahrPreis}
            currency="CHF"
          />
        </td>
      </tr>
      <tr>
        <td>{translatePricem2}</td>
        <td>
          <CurrencyFormat
            value={konfigurationV.totalQmJahrPreis}
            currency="CHF"
          />
        </td>
      </tr>
      <tr>
        <td>{translateParkplatz}</td>
        <td>
          <CurrencyFormat
            value={konfigurationV.parkplatzProJahrPreis}
            currency="CHF"
          />
        </td>
      </tr>
    </Fragment>
  );
};

export default ZusatzoptionenHighlights;
